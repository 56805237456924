<template>
  <div class="hello">
    <img class="indexImg" src="../assets/heka.png" />
    <!-- 贺卡 -->
    <div class="create">
      <div class="createTitle">
        <h3>创建你的AI贺卡（{{ finishVideo.length }}/3）</h3>
        <!-- <div class="imgFlex">
            <img src="../assets/login.png" class="imgStyle" />
            <button class="buttonStyle">等待确认</button>
        </div> -->
        <!-- <img class="addImg" src="../assets/add.png" @click="goAdd" /> -->
      </div>
      <ul class="allfinish">
        <li v-for="(item, index) in finishVideo" :key="index">

          <div class="imgFlex" v-if="item.status == 4">
            <img :src="item.image" class="imgStyle" />
            <el-button type="info" plain size="mini" @click="waitConfirm(item.id, item.image)">等待确认</el-button>
            <div style="font-size: 11px;color:#00000090;">(预计完成还有{{ item.left_time }}) </div>
          </div>

          <div v-if="item.status < 4" class="imgFlex">
            <img :src="item.image" class="imgStyle" />
            <div style="text-align: center;width: 100%;">图片处理中...</div>
            <div style="font-size: 11px;color:#00000090 ;">(预计完成还有{{ item.left_time }}) </div>
          </div>

          <div v-if="item.status == 9" class="imgFlex">
            <img :src="item.image" class="imgStyle" />
            <button style="text-align: center;width: 100%;"
              @click="selectReason(item.id, item.image_feebBack)">查看退回图片</button>
            <!-- <div style="font-size: 11px;color:#00000090 ;">(预计完成还有{{ item.left_time }}) </div> -->
          </div>

          <div v-if="item.status > 4 && item.status < 9" class="imgFlex">
            <img :src="item.image" class="imgStyle" />
            <div style="text-align: center;width: 100%;">视频处理中...</div>
            <div style="font-size: 11px;color:#00000090 ;">(预计完成还有{{ item.left_time }}) </div>
          </div>

          <div v-if="item.status == 10" class="imgFlex">
            <video class="videoBox" controls :src="item.video" width="110" height="196" :poster="item.image">
              <source :src="item.video" type="video/mp4" />
              您的浏览器不支持视频标签。
            </video>
            <el-button type="info" size="mini" @click="downloadVideo(item.video, item.image)">下载视频</el-button>
          </div>
        </li>
        <!-- <li></li>
        <li></li> -->
      </ul>
    </div>
    <!-- 邀请码 -->
    <el-dialog title="请输入邀请码" width="70%" :visible.sync="dialogVisible" :show-close="false" :close-on-press-escape="false"
      :close-on-click-modal="false">
      <div class="inputStyle">
        <input ref="input1" type="number" maxlength="1" v-model="value1" @input="handleInput(1)">
        <input ref="input2" type="number" maxlength="1" v-model="value2" @input="handleInput(2)">
        <input ref="input3" type="number" maxlength="1" v-model="value3" @input="handleInput(3)">
        <input ref="input4" type="number" maxlength="1" v-model="value4" @input="handleInput(4)">
      </div>
      <div style="color: red;" v-if="isShowRedPrompt">{{ promptContent }}</div>
    </el-dialog>
    <!-- 模板 -->
    <div class="module">
      <div class="moduleTitle">
        <h3>模板</h3>
        <p>（选择一套模板，点击视频可预览）</p>
      </div>
      <ul class="video-ul">
        <li class="video-li" v-for="(item, index) in moduleVideo" :key="item.id">
          <div class="moduleVideo">
            <video ref="myVideo" :src="item.video" width="157" height="280" :poster="item.image"
              @click="toggleVideo(index)" @ended="videoEnded(index)">
              <source :src="item.video" type="video/mp4" />
              您的浏览器不支持视频标签。
            </video>
            <i :class="videoStates[index] != 'playing' ? 'iconfont icon-bofang1' : 'iconfont icon-bofang'"
              style="position: absolute;left: 5px;bottom: 5px; font-size: 30px;color:#ffffff;"
              @click="toggleVideo(index + 1)"></i>
          </div>
          <!-- <div> -->
          <div class="goCreateButton"  @click="goCreate(item.id)">去创建</div>
          <!--  -->
          <!-- </div> -->
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// axios.defaults.baseURL = 'https://wx.xinjiaxianglao.com'
import { Message } from 'element-ui';
import wx from "weixin-js-sdk";
const url = window.location.href;
export default {

  data() {
    return {
      moduleVideo: [],
      finishVideo: [],
      dialogVisible: false,
      isShowRedPrompt: false,
      promptContent: '',
      inputValue: [],
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      videoStates: {},
      // isPlaying: false
    };
  },
  computed: {
    isShowImgButton() {
      return this.$store.state.isShowImgButton
    }
  },
  mounted() {
    axios.defaults.withCredentials = true;
    this.cardLogin();
    // this.finishCard();
    this.cardTemplates();
    this.getJsSDK();
  },
  components: {

  },
  watch: {
    value4(value) {
      this.inputValue.push(this.value1);
      this.inputValue.push(this.value2);
      this.inputValue.push(this.value3);
      this.inputValue.push(this.value4);
      console.log('!value', !value)
      if (value) {
        let code = this.inputValue.join("");
        this.inviteVerify(code);
        // this.$refs.input4.focus();
      }
    }
  },
  methods: {
    getJsSDK() {
       console.log('url1234545566', url);
      axios
        .post('https://wx.xinjiaxianglao.com/api/card/wx-config', {
          url: url
        })
        .then((result) => {
          console.log('getJsSDK-result', result);
          let jssdkData = result.data.data
          this.shareVideo(jssdkData.appId, jssdkData.debug, jssdkData.jsApiList, jssdkData.nonceStr, jssdkData.signature, jssdkData.timestamp);
        });
    },
    shareVideo(appId, debug, jsApiList, nonceStr, signature, timestamp) {
      console.log('appId', appId)
      wx.config({
        debug: debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: appId, // 必填，公众号的唯一标识
        timestamp: timestamp, // 必填，生成签名的时间戳
        nonceStr: nonceStr, // 必填，生成签名的随机串
        signature: signature,// 必填，签名
        jsApiList: jsApiList // 必填，需要使用的JS接口列表
      });
      // console.log('this.imageSrc',this.imageSrc);
      wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: 'PALPAL贺卡', // 分享标题
          desc: '快来一起制作新年贺卡吧', // 分享描述
          link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'https://wx.xinjiaxianglao.com/indexIcon.png', // 分享图标
          success: function () {
            // 设置成功
          }
        })
      });
    },
    handleInput(index) {
      if (index == 1) {
        this.value1 = this.value1.replace(/\D/g, '');
        if (this.value1.length > 1) {
          this.value1 = this.value1.charAt(0);
        }
      } else if (index == 2) {
        this.value2 = this.value2.replace(/\D/g, '');
        if (this.value2.length > 1) {
          this.value2 = this.value2.charAt(0);
        }
      } else if (index == 3) {
        this.value3 = this.value3.replace(/\D/g, '');
        if (this.value3.length > 1) {
          this.value3 = this.value3.charAt(0);
        }
      } else if (index == 4) {
        this.value4 = this.value4.replace(/\D/g, '');
        if (this.value4.length > 1) {
          this.value4 = this.value4.charAt(0);
        }
      }

      //自动获取光标
      this.focusNextInput(index);
    },
    focusNextInput(currentInput) {
      const inputs = [this.$refs.input1, this.$refs.input2, this.$refs.input3, this.$refs.input4];

      if (currentInput < inputs.length) {
        if (this[`value${currentInput}`].length > 0) {
          inputs[currentInput].focus();
        }
      } else {
        // 当到达最后一个输入框时，将焦点移回第一个输入框
        this.$refs.input1.focus();
      }
    },
    handleLsatInput() {
      // 过滤非数字字符
      this.value4 = this.value4.replace(/\D/g, '');

      // 限制只能输入一个数字
      if (this.value4.length > 1) {
        this.value4 = this.value4.charAt(0);
      }
    },
    // 处理视频点击事件
    toggleVideo(videoId) {
      // 获取当前视频的播放状态
      console.log('videoId', videoId);
      const isPlaying = this.videoStates[videoId] === 'playing';

      // 暂停所有其他视频
      this.pauseAllOtherVideos(videoId);

      // 根据当前状态播放或暂停点击的视频
      const currentVideo = this.$refs.myVideo[videoId];
      if (isPlaying) {
        currentVideo.pause();
        this.videoStates[videoId] = 'paused';
      } else {
        currentVideo.play();
        this.videoStates[videoId] = 'playing';
      }

      // 更新图标
      this.$forceUpdate(); // 强制更新以确保图标切换
    },
    // 暂停除当前视频外的所有其他视频
    pauseAllOtherVideos(currentVideoId) {
      for (const videoId in this.videoStates) {
        if (videoId !== currentVideoId) {
          const video = this.$refs.myVideo[videoId];
          video.pause();
          this.videoStates[videoId] = 'paused';
        }
      }
    },
    // 判断视频是否正在播放
    // isVideoPlaying(videoId) {
    //   return this.videoStates[videoId] === 'playing';
    // },
    videoEnded(index) {
      // 视频播放结束时切换图标为未播放状态
      // 这里可以根据需要进行其他处理
      this.videoStates[index] = 'paused';

      // 例如，显示一个提示，重置视频播放位置等等
      console.log('Video ended:', index);
      this.$refs.myVideo[index].currentTime = 0;
      this.$forceUpdate();
    },
    // 切换全屏
    // toggleFullscreen(videoId) {
    //   const currentVideo = this.$refs.myVideo[videoId - 1];

    //   if (document.fullscreenElement) {
    //     document.exitFullscreen();
    //   } else {
    //     currentVideo.requestFullscreen().catch(err => {
    //       console.error('全屏请求失败:', err);
    //     });
    //   }
    // },
    // handleInput(event) {
    //   // HTML5 的 number 类型已经不允许非数字输入
    //   // 但是它不支持直接限制输入长度，所以我们需要在input事件中处理
    //   const value = event.target.value;

    //   // 只保留一个数字或空字符串
    //   if (value.length > 1) {
    //     // 将值重置为最后一个数字
    //     this.$nextTick(() => {
    //       event.target.value = value.slice(-1);
    //     });
    //   }

    //   // 如果是v-model绑定，则使用以下方式同步数据：
    //   this.myNumber = value.slice(-1);
    // },
    cardLogin() {
      axios
        .get('https://wx.xinjiaxianglao.com/api/card/home')
        .then((result) => {
          console.log('cardLogin-result', result);
          if (result.data.status == 0) { //用户通过邀请，弹框消失
            this.dialogVisible = false;
          } else if (result.data.status == 1) {//用户未被邀请，弹框出现
            this.dialogVisible = true;
          }
          else if (result.data.status == 9) { //重定位
            window.location.href = result.data.redirectUrl;
            // this.dialogVisible = true;
          }

          //页面挂载的时候请求card是否生成好
          this.finishCard();
        });
    },

    //获取卡片
    finishCard() {
      axios
        .get('https://wx.xinjiaxianglao.com/api/card/user-cards', { withCredentials: true })
        .then((result) => {
          console.log('finishCard-result', result);
          this.finishVideo = result.data;
        });
    },
    inviteVerify(code) {
      axios
        .post('https://wx.xinjiaxianglao.com/api/card/verify-icode',
          { icode: code })
        .then((result) => {
          console.log('inviteVerify-result', result);
          if (result.data.status == 0) {
            //验证码成功，让弹窗消失
            this.dialogVisible = false;
          } else if (result.data.status == 1) {
            //邀请码错误，提示邀请码错误，并将输入内容置空
            this.dialogVisible = true;
            this.isShowRedPrompt = true;
            this.promptContent = result.data.message
            this.setClean();
            setTimeout(() => {
              this.isShowRedPrompt = false;
            }, 3000)

          } else if (result.data.status == 2) {
            //用户已接受邀请，提示
            this.dialogVisible = false;
            this.setClean();
            Message.error(result.data.message);
          } else if (result.data.status == 99) {
            //用户不存在--异常清空
            this.dialogVisible = true;
            this.isShowRedPrompt = true;
            this.promptContent = result.data.message
            this.setClean();
            setTimeout(() => {
              this.isShowRedPrompt = false;
            }, 3000)

          }
        });
    },
    setClean() {
      this.value1 = '';
      this.value2 = '';
      this.value3 = '';
      this.value4 = '';
      this.inputValue = [];
    },
    waitConfirm(id, imageUrl) {
      this.$router.push({
        path: '/waitComfirm',
        query: { id: id, imageUrl: imageUrl }
      });
    },
    selectReason(id, selectReason) {
      this.$router.push({
        path: '/returnImage',
        query: { id: id, selectReason: selectReason }
      });
    },
    cardTemplates() {
      axios
        .get('https://wx.xinjiaxianglao.com/api/card/card-templates')
        .then((result) => {
          // console.log('cardTemplates-result', result);
          this.moduleVideo = result.data;
        });
    },
    goCreate(id) {
      // this.$router.push("/addCharacter/"+id);
      this.$router.push({
        path: '/addCharacter',
        query: { id: id }
      });
    },
    downloadVideo(videoSrc, imageSrc) {
      // 创建一个临时的<a>元素，并将Blob对象的URL作为其href属性
      // const a = document.createElement('a');
      // a.href = videoSrc;

      // 设置下载的文件名
      // a.download = 'downloaded-video.mp4';

      // 将<a>元素添加到文档中
      // document.body.appendChild(a);

      // 模拟点击<a>元素，触发下载
      // a.click();

      // 移除<a>元素
      // document.body.removeChild(a);

      this.$router.push({
        path: "/downloadVideo",
        query: {
          videoSrc: videoSrc,
          imageSrc: imageSrc
        }
      })
    }
  }

}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// .buttonStyle{
//   color: white;
//   border-radius: 10px;
//   border: 1px solid white;
//   background:linear-gradient(to right, #FF9785FF, #FFD88FFF);
// }
/* 隐藏Webkit内核（如Chrome和Safari）中的步进按钮 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* 可能需要这个来修复一些边距问题 */
  text-align: center;
  margin: 0px auto;

}

/* 隐藏火狐中的步进按钮 */
input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}

// /deep/.el-button{
//   width: 100%;
// }
/deep/.el-dialog {
  // background-color: aqua;
  background: linear-gradient(to right, #FF9785FF, #FFD88FFF);
  border-radius: 10px;
}

/deep/.el-dialog__header {
  text-align: center;
}

/deep/.el-dialog__title {
  color: white;
}

.inputStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input {
  background: white;
  width: 25px;
  height: 25px;
  // margin: 0px 10px;
  border-radius: 5px;
}

p,
h3,
ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.hello {
  width: 100wh;
  // height: 100vh;
}

.indexImg {
  width: 100%;
}

.goCreateButton {
  height: 35px;
  line-height: 35px;
  margin: 5px;
  padding: 0px 30px;
  text-align: center;
  color: white;
  font-size: 17px;
  border: 1px solid white;
  border-radius: 30px;
  background: linear-gradient(to right, #FF9785FF, #FFD88FFF);
}

.create {
  /* border: 1px solid red; */
  width: 100%;
  /* height: 50px; */
}

.createTitle {
  text-align: left;
}

.createTitle p {
  /* border: 1px solid red; */
  color: #131313A9;
  font-size: 12px;
  padding: 5px 0px;
}

/* .addImg{
    width: 100%;
  } */
.allfinish {
  /* width: 100%; */
  /* border: 1px solid red; */
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  margin: 10px;

}

.allfinish li {
  /* border: 1px solid rgb(64, 0, 255); */
  margin-right: 7px;
  /* width: 110px; */
  /* height: 110px;
  border-radius: 10px;
  background-color: #CFCFCF; */
}

.imgFlex {
  width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // border: 1px solid rgb(25, 28, 236);
  /* justify-content: flex-start; */
}

.imgStyle {
  // border: 1px solid red;
  width: 110px;
  height: 196px;
  object-fit: contain
}

.module {
  /* border: 1px solid red; */
}

.moduleTitle {
  /* border: 1px solid red; */
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: end;
}

.moduleTitle p {
  /* display: inline-block; */
  font-size: 13px;
}

.video-ul {
  /* border: 1px solid rgb(0, 8, 255); */
  padding: 0px 10px;
  padding-right: 20px;
  box-sizing: border-box;
  width: 100%;
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.video-li {
  width: 157px;
  /* margin: 10px; */
  /* width: 30%;
  border: 1px solid rgb(0, 8, 255); */
}

.videobox {
  width: 100%;
}

.moduleVideo {
  position: relative;
}</style>
  
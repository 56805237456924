<template>
    <div id="waitComfirm">
        <input ref="hiddenFileInput" type="file" style="display: none" accept="image/*"
            @change="getFile($event)">
        <div class="uploadTop">
            <i class="iconfont icon-fanhui" @click="goBack"></i>
        </div>
        <!-- 图片 -->
        <div class="uploadImg">
            <div @click="triggerUpload">
                <!--  :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }" -->
                <img :src="imageBase64Data ? imageBase64Data : imageUrl" width="100%"
                    style="height: 300px; object-fit: contain;" />
            </div>
            <!-- <h6>请上传一张你的照片</h6>
            <p>提示：照片请上传嘴巴闭合、画面清晰、正脸及半身照片</p> -->
            <div class="uploadPrompt">提示：如果您对我们生成的图片感到满意的话，请点击确认图片；否则，您可以点击重新上传，注意只有一次重新上传图片的机会哦！！</div>
            <div class="comfirmImg">
                <el-button type="warning" plain @click="comfirmUpload">确认图片</el-button>
                <el-button type="danger" plain @click="freshUpload">重新上传</el-button>
            </div>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import { Message } from 'element-ui';
export default {
   
    data() {
        return {
            imageBase64Data: "",
            // backgroundImageUrl: require('@/assets/uploadImage.png'),
            id:0,
            imageUrl:""
        }
    },
    mounted() {
        this.id = this.$route.query.id
        console.log(' this.$route.params', this.$route.query.id)
        this.ReturnImage= this.$route.query.imageUrl
    },
    components: {

    },
    methods: {
        triggerUpload() {
            this.$refs.hiddenFileInput.click();
        },
        getFile(e) {
            let reader = new FileReader();
            let file = e.target.files[0];
            // this.selectImage=e.target.files[0];
            // let imgUrlBase64;
            if (file) {
                //将文件以Data URL形式读入页面  
                reader.readAsDataURL(file);
                reader.onload = () => {
                    console.log('imgUrlBase64', reader.result);
                    this.imageBase64Data = reader.result;
                }
            }
        },
        goBack() {
            // this.$router.go(-1);
            this.$router.back();
        },
        comfirmUpload() {
            axios
                .post("https://wx.xinjiaxianglao.com/api/card/confirm-image", {
                    id: this.id,
                    card_sentence: "",
                    audio: "",
                    image: "",
                })
                .then((result) => {
                    console.log("确认图片", result);
                    this.$router.back();
                })
                .catch((err) => {
                    console.log("错误数据", err);
                });
        },
        freshUpload() {
            if (!this.imageBase64Data) {
                // this.uploadPrompt('请点击图片进入相册，之后再重新上传！')
                Message.error('请点击图片进入相册，之后再重新上传！');
                return;
            }
            axios
                .post("https://wx.xinjiaxianglao.com/api/card/confirm-image", {
                    id: this.id,
                    card_sentence: "",
                    audio: "",
                    image: this.imageBase64Data,
                })
                .then((result) => {
                    console.log("重新上传", result);
                    this.$router.back();
                    // this.$store.commit("setShowImgButton",false)
                })
                .catch((err) => {
                    console.log("错误数据", err);
                });
        },
        // uploadPrompt(content) {
        //     this.$alert(content, '', {
        //         confirmButtonText: '确定',
        //         callback: action => {
        //             this.$message({
        //                 type: 'info',
        //                 message: `action: ${action}`
        //             });
        //         }
        //     });
        // },
    }
}
</script>
  
<style scoped lang="less">

.waitComfirm {
    width: 100wh;
    height: 100vh;
    padding: 0px 10px;

    /* border: 1px solid red; */
}
// /deep/.el-message-box {
//  width: 300px;
//  color: red;
// }
.uploadImg {
    /* border: 1px solid red;
    margin: 0px;
    padding: 0px;
    font-size: 17px;
    width: 100%;
    text-align: center;
    color: #131313FF; */

}

.comfirmImg {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.uploadImg h6 {
    /* border: 1px solid red; */
    margin: 0px;
    padding: 0px;
    font-size: 17px;
    width: 100%;
    text-align: center;
    color: #131313FF;
}

.uploadImg p {
    margin: 0px;
    padding: 0px;
    font-size: 10px;
    width: 100%;
    text-align: center;
    color: #131313A6
}

.uploadTop {
    padding: 10px 0px;
    font-size: 30px;
}
.uploadPrompt{
    color: #D3D3D3;
    font-size: 14px;
}
</style>
import Vue from 'vue'
import Router from 'vue-router'

import MainPage from '../views/MainPage'
import AddCharacter from '../views/AddCharacter'
import WaitComfirm from '../views/WaitComfirm'
import VideoDownload from "@/views/VideoDownload"
import ReturnImage from "@/views/ReturnImage"
Vue.use(Router)
 
export default new Router({
  mode: 'history',
  routes: [
    {
        path:'/',
        // name:'helloWorld',
        component:MainPage,
    },
    {
      path: '/addCharacter',
    //   name: 'addCharacter',
      component: AddCharacter
    },
    {
      path: '/waitComfirm',
    //   name: 'addCharacter',
      component: WaitComfirm
    },
    {
      path: '/returnImage',
    //   name: 'addCharacter',
      component: ReturnImage
    },
    {
      path: '/downloadVideo',
      component: VideoDownload,
    }
  ]
});
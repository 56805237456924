import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        isShowImgButton:true
	},
	mutations: {
	},
	actions: {
        setShowImgButton(state,value){
            state.isShowImgButton=value
        }
	}
})
<template>
  <div class="addCharacterBox">
    <!-- <input type="file" accept="image/*" capture="camera" @change="getFile($event)"> -->
    <input ref="hiddenFileInput" type="file" style="display: none" accept="image/*" @change="getFile($event)">
    <i class="iconfont icon-fanhui" @click="goBack"></i>
    <!-- 第一步 -->
    <div>
      <div class="stepTitle">
        <h3>第一步</h3>
        <p>上传你的形象照</p>
      </div>
      <img :src="imageBase64Data ? imageBase64Data : backgroundImageUrl" width="100%" style="object-fit: contain;"
        @click="triggerUpload" />
    </div>


    <!-- 第二步 -->
    <div>
      <div class="stepTitle">
        <h3>第二步</h3>
        <p>请清晰并完整的朗读出文本内容</p>
      </div>
      <div class="secondStepContent">
        <div class="backgroundTitleOut">
          <div class="backgroundTitleInner">复刻声音</div>
        </div>
        <div class="backgroundContent">
          在一个夏日的黄昏，我们走进山里，当都市的喧闹渐渐远离的时候，忽然发现我们已
          经置身于一个绿色的世界。
          群山、溪水在四周环绕着，阳光缓缓流过，那种静谧与安宁深深感动了我，这种声音
          应该属于童年母亲讲述的故事里，但这个时候却出现得那么和谐。
        </div>
        <div class="retry" v-if="!isShowTryListenning">
          <div class="listenning">
            <i :class="isPlayRecord ? 'iconfont icon-bofang listenIcon' : 'iconfont icon-bofang1 listenIcon'"
              @click="onTry"></i>
            <h3>试听</h3>
            <!-- <p>（请录制你的声音）</p> -->
          </div>
          <div class="listenning" @click="reset">
            <!-- <i :class="isPlayRecord ? 'iconfont icon-bofang listenIcon' : 'iconfont icon-bofang1 listenIcon'"
              ></i> -->
            <h3>重试</h3>
            <!-- <p>（请录制你的声音）</p> -->
          </div>
        </div>
        <div v-else>
          <RecordAudio ref="recordAudioRef" @sendSounds="sendSounds"></RecordAudio>
        </div>

      </div>
    </div>
    <!-- 试听 -->
    <!-- <div>
      
      <RecordAudio ref="recordAudioRef" @sendSounds="sendSounds"></RecordAudio>
    </div> -->
    <!--  -->
    <div class="newCongratulate">
      <!-- 新年祝词Title -->
      <div class="stepTitle">
        <h3>第三步</h3>
        <p>选择你的新年贺词</p>
      </div>
      <div class="thirdStepContent">
        <div class="backgroundTitleOut">
          <div class="backgroundTitleInner">新年贺词{{ cardSentencesIndex + 1 }}/{{ cardSentences.length }}</div>
        </div>
        <div class="backgroundCenter">
          <div class="backgroundContent">
            {{ cardSentences[cardSentencesIndex].text }}
          </div>
          <div class="txtCenter">
            <div class="nextTitle">
              <i class="iconfont icon-shuaxin"></i>
              <p @click="nextSentences">下一条</p>
            </div>
          </div>

        </div>

      </div>
    </div>
    <!-- " -->
    <el-dialog title="提示" width="50%" :visible.sync="dialogVisible" :before-close="handleClose">
      <div><span>{{ dialogContent }}</span></div>
      <el-button size="mini" v-if="isShowSelectButton" type="danger" round @click="goIndex">去查看</el-button>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
    <div class="uploadStyle">
      <el-button v-loading="loading" round @click="uploadGetCharacter">点击上传</el-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RecordAudio from "../components/RecordAudio.vue";
// let audioBase64Data;
// let duration;
export default {
  data() {
    return {
      // moduleImgArr: [],
      // selectImage:'',
      id: 0,
      audioDataBlob: '',
      audioBase64Data: "",
      imageBase64Data: '',
      audioElement: new Audio(),
      cardSentences: [
        {
          text: ""
        }
      ],
      cardSentencesIndex: 0,
      value1: 0,
      backgroundImageUrl: require('@/assets/uploadImage.png'),
      dialogVisible: false,
      dialogContent: "",
      isPlayRecord: false,
      isShowSelectButton: false,
      loading: false,
      isShowTryListenning: true
      // audioData: null,
    };
  },
  mounted() {
    this.id = this.$route.query.id
    console.log(' this.$route.params', this.$route.query.id)
    // this.cardModuleImg();
    this.getCardSentences();
   
  },
  methods: {
    //试听
    onTry() {
      // let audioElement = new Audio();
      this.isPlayRecord = !this.isPlayRecord;
      if (this.isPlayRecord) {
        // this.$refs.recordAudioRef.playAudio(); 
        // setTimeout(() => {               //2切换播放和暂停图标：之所以不用音频duration时间去定时，切换暂停图标，是由于Audio 对象没有stop（）方法，只有pause（）.
          // this.isPlayRecord = false
        // }, duration * 1000)
        this.audioElement.src = URL.createObjectURL(this.audioDataBlob);
        this.audioElement.play();
        this.audioElement.addEventListener('ended', () =>{ //1切换播放和暂停图标：ended 事件是在音频播放结束时触发的一次性事件（Audio 对象并没有直接的 "播放完毕" 事件。自己添加 监听ended【一次性事件】）。如果不断切换播放和暂停按钮，音频始终没有结束播放的话，会出现多个相同类型的事件监听器，这些监听器一旦监听到 ended 就会执行一次 并消失
             this.isPlayRecord = false
        });  
      } else {
        // this.$refs.recordAudioRef.stopPlayAudio();
        // if (this.audioElement) {
        console.log('this.audioElement.pause()1', this.audioElement.pause())
        this.audioElement.pause();
        console.log('this.audioElement.pause()2', this.audioElement.pause())
        // this.audioElement.currentTime = 0;
        // }
      }
    },
    reset() {
      this.isShowTryListenning = true;
    },
    triggerUpload() {
      this.$refs.hiddenFileInput.click();
    },
    getFile(e) {
      let reader = new FileReader();
      let file = e.target.files[0];
      // this.selectImage=e.target.files[0];
      // let imgUrlBase64;
      if (file) {
        //将文件以Data URL形式读入页面  
        reader.readAsDataURL(file);
        reader.onload = () => {
          console.log('imgUrlBase64', reader.result);
          this.imageBase64Data = reader.result;
        }
      }
    },
    goBack() {
      // this.$router.go(-1);
      this.$router.back();
    },
    formatTooltip(val) {
      return val / 100;
    },
    // cardModuleImg() {
    //   axios
    //     .get('https://wx.xinjiaxianglao.com/api/card/card-templates')
    //     .then((result) => {
    //       console.log('result', result);
    //       // this.moduleImgArr = result.data;
    //     });
    // },
    getCardSentences() {
      axios
        .get('https://wx.xinjiaxianglao.com/api/card/card-sentences')
        .then((result) => {
          console.log('card-sentences-result', result);
          this.cardSentences = result.data;
        });
    },
    nextSentences() {
      if (this.cardSentencesIndex < this.cardSentences.length - 1) {
        this.cardSentencesIndex++;
      } else {
        this.cardSentencesIndex = 0;
      }
    },
    sendSounds(audioData) {
      // this.audioData = audioData.blob;
      // duration = audioData.duration;
      this.audioDataBlob = audioData.blob;
      if (this.audioDataBlob) {
        //试听显示 
        this.isShowTryListenning = false;
        // this.isPlayRecord=false
      }
      this.convertWavBlobToBase64(this.audioDataBlob).then(base64Data => {
        console.log('Base64 encoded data:', base64Data);
        this.audioBase64Data = base64Data;
        // console.log('this.audioBase64Data',this.audioBase64Data)
        // this.uploadGetCharacter(base64Data);
      }).catch(err => {
        console.error('Error converting blob to base64:', err);
      });
    },
    uploadGetCharacter() {
      this.loading = true;
      if (!this.audioBase64Data || !this.imageBase64Data) {
        this.loading = false;
        this.dialogVisible = true;
        this.dialogContent = "请同时上传图片和音频哦 ！"
        return;
      }
      // console.log('this.imageBase64Data', this.imageBase64Data);
      axios
        .post("https://wx.xinjiaxianglao.com/api/card/create-card", {
          card_template_id: this.id,
          card_sentence: this.cardSentences[this.cardSentencesIndex].text,
          audio: this.audioBase64Data,
          image: this.imageBase64Data,
        })
        .then((result) => {
          console.log("resultsdfrtytrewertytrertytre", result);
          this.loading = false;
          if (result.data.status == 0) {
            this.dialogVisible = true;
            this.isShowSelectButton = true;
            this.dialogContent = "制作完成之后将在首页展示，请留意观察！！！(制卡时间:9:30-18:00)"
          } else {
            this.dialogVisible = true;
            // this.isShowSelectButton=true;
            this.dialogContent = result.data.message;
          }

        })
        .catch((err) => {
          this.loading = false;
          console.log("错误数据", err);
        });
    },
    goIndex() {
      this.$router.back();
    },
    convertWavBlobToBase64(wavBlob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = function (event) {
          // 当读取操作完成时，result属性包含的是data:URL格式的base64数据
          resolve(event.target.result);
        };

        fileReader.onerror = function (error) {
          reject(error);
        };

        // 使用readAsDataURL方法读取Blob，并将其转换为data:URL格式，其中包含了base64编码的数据
        fileReader.readAsDataURL(wavBlob);
      });
    },
    // handleClose(done) {
    //     this.$confirm('确认关闭？')
    //       .then(_ => {
    //         done();
    //       })
    //       .catch(_ => {});
    //   }
  },
  components: {
    RecordAudio
  },
}
</script>
  
<style scoped lang="less">
.addCharacterBox {
  width: 100wh;
  // height: 100vh;
  padding: 0px 10px;

  // border: 1px solid red;
}

.uploadImg {
  // border: 1px solid red;
  margin: 0px;
  padding: 0px;
  font-size: 17px;
  width: 100%;
  text-align: center;
  color: #131313FF;
}

/deep/.el-loading-mask {
  border-radius: 50px;
}

/deep/.el-loading-spinner .path {
  stroke: rgb(246, 158, 52);
}


/* .uploadImg p {
  margin: 0px;
  padding: 0px;
  font-size: 10px;
  width: 100%;
  text-align: center;
  color: #131313A6
} */



.uploadTop {
  padding: 10px 0px;
  font-size: 30px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.el-slider__runway {
  width: 95%;
  margin: 20px 10px;
}

.setModuleImgSize {
  width: 100%;
  height: 100%;
}

.stepTitle {
  // border: 1px solid red;
  padding: 20px 0px;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;
}

.stepTitle h3 {
  margin: 0px;
  // padding: 5px 0px;
  font-size: 17px;
  width: 100%;
  text-align: left;
  color: #131313FF;
  // border: 1px solid red;
}

.stepTitle p {
  margin: 0px;
  padding: 0px;
  font-size: 15px;
  width: 100%;
  text-align: left;
  padding: 5px 0px;
  // border: 1px solid red;
}

.secondStepContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .backgroundTitleOut {
    width: 80%;
    height: 50px;
    line-height: 50px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #DE5037FF;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .backgroundTitleInner {
      width: 90%;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      background-color: #FFEDADFF;
      text-align: center;
      color: #5F3C00FF;
    }
  }

  .backgroundContent {
    border: 1px solid red;
    width: 100%;
    // height: 300px;
    background: linear-gradient(140deg, #eb5d44, #DE5037);
    color: #FFEDADFF;
    line-height: 30px;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 15px;
  }

  .retry {
    width: 100%;
    // border: 1px solid red;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .listenning {
      width: 100px;
      height: 40px;
      line-height: 40px;
      margin: 30px 0px;
      border-radius: 20px;
      // border: 1px solid red;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFEDADFF;
      color: #5F3C00FF;

      .listenIcon {
        font-size: 20px;
        padding: 0px 5px;

      }
    }
  }


}

.thirdStepContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .backgroundTitleOut {
    width: 80%;
    height: 50px;
    line-height: 50px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: #DE5037FF;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .backgroundTitleInner {
      width: 90%;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
      background-color: #FFEDADFF;
      text-align: center;
      color: #5F3C00FF;
    }
  }

  .backgroundCenter {
    background: linear-gradient(140deg, #eb5d44, #DE5037);
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 20px;
    color: #FFEDADFF;

    .backgroundContent {
      width: 100%;
      // height: 300px; 
      line-height: 30px;
      font-size: 15px;
    }

    .txtCenter {
      width: 100%;
      // padding: 0px auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .nextTitle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

  }

}

.changeContent {
  /* width: 90%; */
  padding: 10px;
  border-radius: 10px;

}

.newCongratulate {}



.uploadStyle {
  // background-color: #FFE9E5FF;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadStyle /deep/.el-button {
  width: 100%;
  color: #5F3C00FF;
  background-color: #FFEDADFF;
}

// .uploadStyle {
//   height: 100px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
</style>
<template>
  <!--点击开始录音,按钮颜色切换,再次点击结束录音 -->
  <div class="recordBox">
    <el-row type="flex" justify="start">
      <div class="BaseRecorder">
        <!--  :type= "microphoneActive ?'danger':'info'"   :circle="microphoneActive ?false:true" -->
        <el-button type="danger" :icon="microphoneActive? 'el-icon-loading':'el-icon-microphone'" circle
          @click="activeMicphoneHandler"></el-button>
          <p>点击录制音频</p>
      </div>
    </el-row>
  </div>
</template>
<!--danger  -->
<script>
import Recorder from "js-audio-recorder";
// let lamejs = require("lamejs");
export default {
  // name: "home",
  // props:{
  //   isPlayRecord:Boolean
  // },
  data() {
    return {
      microphoneActive: false,
      recorder: null,
      isRecording: false,
      recordingData: undefined,
    };
  },
  watch: {
    microphoneActive(value) {
      // 如果麦克风激活了
      if (value) {
        this.startRecorder();
      } else {
        this.stopRecorder();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.recorder = new Recorder({
        sampleBits: 16,
        sampleRate: 48000,
        numChannels: 1,
        compiling: false,
      });
    },
    activeMicphoneHandler() {
      this.microphoneActive = !this.microphoneActive;
    },
    startRecorder() {
      this.recorder.start().then(
        () => {
        },
        (error) => {
          // 出错了
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    stopRecorder() {
      this.recorder.stop();
      console.log('this.recorder.getWAVBlob()',this.recorder.getWAVBlob())
      // this.getMp3Data(this.recorder.getWAVBlob())
      const audioData = {
        duration: this.recorder.duration,
        blob: this.recorder.getWAVBlob()
      }
      this.$emit('sendSounds', audioData);
    },
    playAudio() {
      console.log('this.recorder.getWAVBlob()',this.recorder.getWAVBlob())
      this.recorder.play();
    },
    stopPlayAudio(){
      console.log('this.recorder.getWAVBlob()',this.recorder.getWAVBlob())
      this.recorder.stopPlay();
    }

    /**
    * 文件格式转换 wav-map3
    * */
    // getMp3Data(Blob) {
    //   const mp3Blob = this.convertToMp3(Blob);
    //   console.log('mp3Blob',mp3Blob)
    //   // recorder.download(mp3Blob, "recorder", "mp3");
    // },
    // convertToMp3(wavDataView) {
    //   // 获取wav头信息
    //   const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
    //   const { channels, sampleRate } = wav;
    //   const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
    //   // 获取左右通道数据
    //   const result = this.recorder.getChannelData();
    //   const buffer = [];
    //   const leftData =
    //     result.left &&
    //     new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
    //   const rightData =
    //     result.right &&
    //     new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
    //   const remaining = leftData.length + (rightData ? rightData.length : 0);
    //   const maxSamples = 1152;
    //   for (let i = 0; i < remaining; i += maxSamples) {
    //     const left = leftData.subarray(i, i + maxSamples);
    //     let right = null;
    //     let mp3buf = null;
    //     if (channels === 2) {
    //       right = rightData.subarray(i, i + maxSamples);
    //       mp3buf = mp3enc.encodeBuffer(left, right);
    //     } else {
    //       mp3buf = mp3enc.encodeBuffer(left);
    //     }
    //     if (mp3buf.length > 0) {
    //       buffer.push(mp3buf);
    //     }
    //   }
    //   const enc = mp3enc.flush();
    //   if (enc.length > 0) {
    //     buffer.push(enc);
    //   }
    //   return new Blob(buffer, { type: "audio/mp3" });
    // },
    // playRecorder() {
    //   if (this.props.playAudio) {
    //     this.recorder.play();
    //   }
    // },
  },
};
</script>
<style  scoped>
.recordBox {
  /* border: 1px solid rebeccapurple; */
  height: 150px;
  display: flex;
  padding-top: 30px;
  justify-content: center;
  align-items: center;
}
.BaseRecorder{
  /* border: 1px solid rebeccapurple; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.el-button {
  /*  */
  width: 100px;
  height: 100px;
  font-size: 30px;
}
</style>

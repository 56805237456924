<template>
    <div id="waitComfirm">
        <input ref="hiddenFileInput" type="file" style="display: none" accept="image/*"
            @change="getFile($event)">
        <div class="uploadTop">
            <i class="iconfont icon-fanhui" @click="goBack"></i>
        </div>
        <!-- 图片 -->
        <div class="uploadImg">
            <div @click="triggerUpload">
                <!--  :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }" -->
                <img :src="imageBase64Data ? imageBase64Data : backgroundImageUrl" width="100%"
                    style=" object-fit: contain;" />
                <div class="uploadPrompt">错误提示：您之前的图片存在{{selectReason}}问题,导致上传失败；请点击重新上传符合规范的图片哦</div>
            </div>
             <el-button type="danger" plain @click="freshUpload">重新上传</el-button>
        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import { Message } from 'element-ui';
export default {
   
    data() {
        return {
            imageBase64Data: "",
            backgroundImageUrl: require('@/assets/uploadImage.png'),
            id:0,
            selectReason:''
        }
    },
    mounted() {
        this.id = this.$route.query.id
        console.log(' this.$route.params', this.$route.query.id)
        this.selectReason= this.$route.query.selectReason
    },
    components: {

    },
    methods: {
        triggerUpload() {
            this.$refs.hiddenFileInput.click();
        },
        getFile(e) {
            let reader = new FileReader();
            let file = e.target.files[0];
            // this.selectImage=e.target.files[0];
            // let imgUrlBase64;
            if (file) {
                //将文件以Data URL形式读入页面  
                reader.readAsDataURL(file);
                reader.onload = () => {
                    console.log('imgUrlBase64', reader.result);
                    this.imageBase64Data = reader.result;
                }
            }
        },
        goBack() {
            // this.$router.go(-1);
            this.$router.back();
        },
        // comfirmUpload() {
        //     axios
        //         .post("https://wx.xinjiaxianglao.com/api/card/confirm-image", {
        //             id: this.id,
        //             card_sentence: "",
        //             audio: "",
        //             image: "",
        //         })
        //         .then((result) => {
        //             console.log("确认图片", result);
        //             this.$router.back();
        //         })
        //         .catch((err) => {
        //             console.log("错误数据", err);
        //         });
        // },
        freshUpload() {
            if (!this.imageBase64Data) {
                // this.uploadPrompt('请点击图片进入相册，之后再重新上传！')
                Message.error('请点击图片进入相册，之后再重新上传！');
                return;
            }
            axios
                .post("https://wx.xinjiaxianglao.com/api/card/confirm-image", {
                    id: this.id,
                    card_sentence: "",
                    audio: "",
                    image: this.imageBase64Data,
                })
                .then((result) => {
                    console.log("重新上传", result);
                    this.$router.back();
                    // this.$store.commit("setShowImgButton",false)
                })
                .catch((err) => {
                    console.log("错误数据", err);
                });
        },
    }
}
</script>
  
<style scoped lang="less">

.waitComfirm {
    width: 100wh;
    height: 100vh;
    padding: 0px 10px;

}

.uploadImg {
//    border: 1px solid red;
}

// .comfirmImg {
//     border: 1px solid red;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
// }
/deep/ .el-button{
    width: 100%;
}
.uploadImg h6 {
    /* border: 1px solid red; */
    margin: 0px;
    padding: 0px;
    font-size: 17px;
    width: 100%;
    text-align: center;
    color: #131313FF;
}

.uploadImg p {
    margin: 0px;
    padding: 0px;
    font-size: 10px;
    width: 100%;
    text-align: center;
    color: #131313A6
}

.uploadTop {
    padding: 10px 0px;
    font-size: 30px;
}
.uploadPrompt{
    color: #D3D3D3;
    font-size: 15px;
}
</style>
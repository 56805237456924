<template>
    <div class="DownLoadvideoStyle">
        <div class="videoStyle">
            <!-- <div class="uploadTop">
                <i class="iconfont icon-fanhui" @click="goBack"></i>
            </div> -->
            <video controls :src="videoSrc" width="100%" :poster="imageSrc">
                <source :src="videoSrc" type="video/mp4" />
                您的浏览器不支持视频标签。
            </video>
            <img v-if="isShowIconPrompt" src="../assets/arrowRight.png" class="imageStyle" alt="">
            <div class="editionText">（通过浏览器下载之后，视频可直接分享到微信哦）</div>
            <a class="aStyle" style="text-decoration: none;" :href="videoSrc" download="video.mp4" @click="downVideo">下载视频</a>
        </div>
        <div>
            <div class="edition">
                <img class="login" src="../assets/login.png" />
                <div class="editionText">PAI.PAL-AI数字人</div>
            </div>
            <div class="editionText2">Copyright © 2024 上海市上海聚界信息科技有限公司 All Rights Reserved.</div>
        </div>
    </div>
</template>

<script>
import wx from "weixin-js-sdk"
import axios from "axios";

export default {
    data() {
        return {
            videoSrc: '',
            imageSrc: '',
            isShowIconPrompt: false
        }
    },
    mounted() {
        this.videoSrc = this.$route.query.videoSrc;
        this.imageSrc = this.$route.query.imageSrc;
        this.getJsSDK();
        // this.shareVideo();
    },
    methods: {
        shareVideo(appId,debug,jsApiList,nonceStr,signature,timestamp) {
            // console.log('signature',signature)
            wx.config({
                debug: debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: appId, // 必填，公众号的唯一标识
                timestamp: timestamp, // 必填，生成签名的时间戳
                nonceStr: nonceStr, // 必填，生成签名的随机串
                signature: signature,// 必填，签名
                jsApiList:jsApiList // 必填，需要使用的JS接口列表
            });
            // console.log('this.imageSrc',this.imageSrc);
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                wx.updateAppMessageShareData({
                    title: 'PALPAL贺卡', // 分享标题
                    desc: '快来一起制作新年贺卡吧', // 分享描述
                    // link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: this.imageSrc, // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })
            });
        },
        getJsSDK() {
            const url = window.location.href;
           console.log('url1234545566', url);
            axios
                .post('https://wx.xinjiaxianglao.com/api/card/wx-config',{
                    url:url
                })
                .then((result) => {
                    console.log('getJsSDK-result', result);
                    let jssdkData=result.data.data
                    this.shareVideo(jssdkData.appId, jssdkData.debug, jssdkData.jsApiList, jssdkData.nonceStr, jssdkData.signature, jssdkData.timestamp);  
                });
        },
        downVideo() {
            this.isShowIconPrompt = true,
                setTimeout(() => {
                    this.isShowIconPrompt = false
                }, 3000)
        }
    }
}
</script>

<style>
.DownLoadvideoStyle {
    width: 100wh;
    height: 100vh;
    /* border: 1px solid red;  */
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.videoStyle {
    /* border: 1px solid red; */
    position: relative;
}

.imageStyle {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    top: 0px
}

.aStyle {
    display: block;
    width: 100%;
    height: 30px;
    background-color: #DE5037;
    margin: 20px 0px;
    line-height: 30px;
    border: 1px solid #ff430590;
    border-radius: 10px;
    text-align: center;
    color: white;
}

.edition {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editionText {
    /* border: 1px solid #ff430590; */
    font-size: 12px;
    padding: 0px 10px;
    text-align: center;
}

.editionText2 {
    font-size: 8px;
}

.login {
    width: 18px;
    height: 18px;
}
</style>